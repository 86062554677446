.mainPageWrapperMain {
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  padding: 1.5rem 3rem;
  border-top-left-radius: 1.5rem;
  position: relative;
  width: 100%;
}

.mainPageWrapperTitle {
  font-size: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.mainPageWrapperContainer {
  padding: 1rem 0;
}
