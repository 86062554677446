.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* isolation: isolate; */
  position: fixed;
  padding: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  gap: 16px;
  max-height: 90vh;
  overflow-y: auto;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  width: fit-content;
}
