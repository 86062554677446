.tableWrapperContainer {
  max-height: 75vh;
  overflow: auto;
  border: 1px solid rgb(194, 194, 194);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 8px;
  width: 90%;
  background-color: #fff;
  margin-top: 1rem;
}

.demandGenerationTable {
  width: 100%;
  border-collapse: collapse;
}

.headerCell {
  padding: 16px 8px;
  border-bottom: 1px solid rgb(194, 194, 194);
  background-color: #dfdfdf;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 500;
}
.headerCell:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 600;
}

.demandGenerationTable td,
.demandGenerationTable th {
  padding: 12px 8px;
  text-align: left;
  border: 1px solid rgb(194, 194, 194);
}

/* Remove overlapping borders for the first and last cells of each row */
.demandGenerationTable tr td:first-child,
.demandGenerationTable tr th:first-child {
  border-left: none;
}

.demandGenerationTable tr td:last-child,
.demandGenerationTable tr th:last-child {
  border-right: none;
}

/* for cells in the first and last row */
.demandGenerationTable tr:first-child td,
.demandGenerationTable tr:first-child th {
  border-top: none;
}

.demandGenerationTable tr:last-child td,
.demandGenerationTable tr:last-child th {
  border-bottom: none;
}

.totalCell {
  border-left: 1px solid rgb(194, 194, 194);
  border-right: 1px solid rgb(194, 194, 194);
  border-top: none;
}
.hubStoreCell {
  border-left: 1px solid rgb(194, 194, 194);
  border-right: 1px solid rgb(194, 194, 194);
  border-bottom: none;
}

.noVerticalBorders {
  border-top: none;
  border-bottom: none;
  background-color: #dfdfdf;
}
.hubStoreCell,
.totalCell,
.demandStoreCell {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 400;
}
.weeklyTotalCell,
.hubStoreCell,
.totalCell {
  font-weight: bold;
}

.hubStoreCell,
.totalCell {
  background-color: #dfdfdf;
}

.weeklyTotalCell {
  background-color: #f8f8f8;
}
