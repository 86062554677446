.locationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 16px;
  isolation: isolate;
  position: absolute;
  width: 85%;
  min-height: 65%;
  /* max-height: 85%; */
  overflow-y: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.widthFitContent {
  width: fit-content;
}

.maxWidth {
  width: 1250px;
}

.locationModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 10px;
}

.locationModalHeaderButtons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.locationModalPageTitle {
  font-size: 28px;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  height: 100%;
}

.locationAvailabilityDetailsHolder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 1rem;
  overflow-y: scroll;
  max-height: 60vh;
  border: thin solid #c2c2c2;
  border-radius: 0.5rem;
  padding: 1rem;
}

.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationEditComponentsHolder {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem;
}
