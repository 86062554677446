.daySettingsManagerMain {
  width: 100%;
}

.daySettingsManagerContainer {
  width: 100%;
}

.addNewDaySettingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  gap: 1rem;
  background-color: #f5f5f5;
  width: 100%;
  padding: 1rem;
  border-top: thin solid #c2c2c2;
}

.addNewDaySettingTitle {
  font-size: 14px;
  font-weight: bold;
}

.addNewDaySettingInputsHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.addNewDaySettingInputsHolder input,
select {
  background-color: white;
}

.addNewDaySettingTimeContainer {
  display: flex;
}

.timesHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.hourOption {
  font-size: 12px;
}
