.selectTireSizeContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
}

.selectTireSizeTitle {
	font-size: 21px;
}

.tireSizeButtonContainer {
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.tireSizeButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
  gap: 16px;
  height: 76px;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.tireSizeButtonRadio {
	width: 24px;
	height: 24px;
	background: #ffffff;
	border-radius: 100px;
}

.tireSizeButtonRadioBorder1 {
	border: 8px solid #d70000;
}

.tireSizeButtonRadioBorder2 {
	border: 1px solid #d70000;
}

.tireSizeButtonInches {
	width: 64px;
	height: 60px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 60px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #141414;
}

.tireSizeButtonTextContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;
	width: 133px;
	height: 42px;
}

.tireSizeButtonTextInnerContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
}

.tireSizeButtonTextType {
	width: 40px;
	height: 21px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	display: flex;
	align-items: center;
	color: #767676;
}

.tireSizeButtonTextValue {
	width: 85px;
	height: 21px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	display: flex;
	align-items: center;
	color: #141414;
}

.customTireSizeButtonTitle {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	display: flex;
	align-items: center;
	color: #141414;
}
@media (max-width: 1023px) {
	.selectTireSizeContainer {
		min-height: 200px;
		min-width: '';
	}
	.tireSizeButtonContainer {
		width: 100%;
	}
}
@media (max-width: 350px) {
	.tireSizeButton {
		width: 100%;
	}
	.tireSizeButtonInches {
		font-size: 32px;
		width: 44px;
		height: 40px;
	}
}
