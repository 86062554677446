.locationButton {
	all: unset;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.locationAddButton {
	color: white;
	background-color: #d70000;
	font-weight: bold;
	border: thin solid #d70000;
	border-radius: .25rem;
	padding: 0.05rem .5rem .1rem;
}

.locationAddButton:hover {
	box-shadow: 0 0 .25rem 0 rgba(0, 0, 0, .5);
}

.locationEditButton {
	color: #d70000;
	background-color: white;
	font-weight: bold;
	width: fit-content;
	margin-left: .25rem;
}

.locationEditButton:hover {
	text-decoration: underline;
}

.locationButtonsHolder {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}