.editVanModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  isolation: isolate;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  max-height: 100%;
  overflow-y: auto;
}
.editVanModalPageTitle {
  font-size: 28px;
}
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  gap: 8px;
}
.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}
