.eventContainer {
  text-align: left;
  cursor: pointer;
  font-size: 0.9rem;
}

.block {
  display: inline;
}
.primary {
  font-weight: bold;
}
/* custom toolbar */
.toolbarContainer {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid #c2c2c2;
  border-right: 1px solid #c2c2c2;
  border-left: 1px solid #c2c2c2;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.monthAndYear {
  font-weight: 700;
  font-size: 22px;
  justify-self: center;
}
.toolBarButtonGroup {
  display: flex;
  gap: 1.5rem;
  justify-self: end;
}
.calendarNavButton {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
}

.previous {
  transform: rotate(270deg);
}

.next {
  transform: rotate(90deg);
}

.dot {
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

.chooseLocationContainer {
  box-shadow: 0 0 .25rem 0 rgba(0, 0, 0, 0.25) inset;
  padding: .75rem 2rem;
  border-radius: .5rem;
  background-color: #D70000;
}

.chooseLocationText {
  font-weight: 700;
  font-size: 18px;
  color: white;
}