.locationFormContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  max-height: 60vh;
  overflow-y: scroll;
  width: 100%;
  max-width: 25rem;
}

.locationFormTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 135%;
  display: flex;
  align-items: center;
}

.doubleFieldContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.twoColumnsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.locationFormSubtitle {
  padding-top: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.locationFormDescription {
  font-style: italic;
  font-size: 12px;
  padding: 0.5rem;
  background-color: #f9f9f9;
  border: thin solid #c2c2c2;
  border-radius: 0.25rem;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 308px;
}

.wideTextField {
  width: 320px;
}

.narrowTextField {
  width: 156px;
}

.productionModeSmallText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.timeSlotDescriptionContainer {
  width: 100%;
  padding: 1rem;
  font-size: 14px;
  border: thin solid #c2c2c2;
  border-radius: 0.25rem;
  background-color: #f9f9f9;
  font-style: italic;
}

.timeSlotDescription {
  color: red;
  font-weight: bold;
}

.averageTimeDescription {
  color: #141414;
  font-weight: 400;
  padding-bottom: 0.5rem;
  text-align: right;
}

.averageTimeValues {
  background-color: #c2c2c2;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sameDayRadioButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}
