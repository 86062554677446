.alertsManagerMain {
  border: thin solid #c2c2c2;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.alertsManagerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}
.alertsManagerTitle {
  font-size: 22px;
}
.alertsManagerDescription {
  font-size: 12px;
  font-style: italic;
  padding-bottom: 0.15rem;
}

.alertsManagerSubsectionContainer {
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  border-top: thin solid #c2c2c2;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.emailAlertsSubsectionContainer {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 15rem;
  overflow-y: scroll;
  gap: 0.5rem;
  padding: 1rem;
}

.cellPhoneAlertsSubsectionContainer {
  border-right: thin solid #c2c2c2;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 15rem;
  overflow-y: scroll;
  gap: 0.5rem;
  padding: 1rem;
}
