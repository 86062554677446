.weekCalendarMain {
  display: flex;
  border: thin solid #c2c2c2;
  border-radius: 0.5rem;
  align-items: center;
}

.weekCalendarTitle {
  font-size: 22px;
  padding: 1rem;
}

.weekCalendarContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem 2rem;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: thin solid #c2c2c2;
}

.weekCalendarDay {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.weekCalendarDayName {
  font-weight: bold;
  text-align: center;
}
