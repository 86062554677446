.navDrawerContainer {
  padding: 1.5rem 0 0;
  width: 215px;
}
.logoHolder {
  margin-bottom: 1.5rem;
}
.logo {
  width: 150px;
}
.header {
  padding: 0 1.5rem;
}
.headerInfo {
  margin-bottom: 0.5rem;
}
.link {
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  box-sizing: border-box;
  border-left: 3px solid transparent;
  display: block;
  text-decoration: none;
  text-decoration-color: inherit;
}
.link:link {
  color: inherit;
}
.link:visited {
  color: inherit;
}
.link.chosen {
  border-left: 3px solid red;
  font-weight: bold;
  font-size: large;
}
.link:hover {
  font-weight: bold;
}
.link.feedback {
  margin-top: 2rem;
}
.linksContainer {
  padding-top: 1.5rem;
}
.userContainer {
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  box-sizing: border-box;
  display: block;
}
.userName {
  font-style: normal;
  font-weight: bold;
  font-size: 12pt;
}
.userInfo {
  font-style: normal;
  font-weight: normal;
  font-size: 10pt;
}
.csPortal {
  font-size: 16pt;
  font-weight: bold;
  font-style: normal;
}
.weekNumber {
  font-weight: normal;
  font-style: normal;
  font-size: 10pt;
}
.feedbackButtonsHolder {
  align-items: center;
  display: flex;
  padding: 1rem 0;
  width: fit-content;
  gap: 15px;
}
.feedbackButtonsHolderLink {
  display: inline;
  color: rgb(26, 115, 232);
  text-decoration: none;
}
.feedbackButtonsHolderLink:visited {
  display: inline;
  color: rgb(26, 115, 232);
  text-decoration: none;
}
