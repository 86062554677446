.orderActionsContainer {
  display: flex;
}
.button {
  all: unset;
  cursor: pointer;
  color: #d70000;
  box-sizing: content-box;
  height: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.button:disabled {
  color: rgba(0, 0, 0, 0.6);
  cursor: not-allowed;
}

.inputButton {
  all: unset;
  cursor: pointer;
  color: #d70000;
  font-size: 12px;
}
.inputButton:hover {
  text-decoration: underline;
}
.additionalActions {
  display: flex;
  align-items: center;
}
.disabled {
  color: #c7c7c7;
}
.line {
  width: 20px;
  height: 0px;
  border: 1px solid #c2c2c2;
  transform: rotate(90deg);
}
.button:nth-child(3) {
  border-right: thin solid #c2c2c2;
}
.button:nth-child(4) {
  border-right: thin solid #c2c2c2;
}
.button:nth-child(5) {
  border-right: thin solid #c2c2c2;
}
.button:nth-child(6) {
  border-right: thin solid #c2c2c2;
}
.freeOrderTag {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #d70000;
}
.freeOrderText {
  color: black;
  font-weight: 700;
}
