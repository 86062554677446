.header {
  font-weight: bold;
  font-size: 2rem;
}

.helperContainer {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
}

.examples {
  margin: 0.25rem 0;
}
