.secondaryButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  border: 1px solid #d70000;
  border-radius: 100px;
  height: 45px;
  background: #ffffff;
}

.secondaryButton:hover span {
  color: #ffffff;
  cursor: pointer;
}

.secondaryButton:hover {
  background: #d70000;
  cursor: pointer;
}

.secondaryButtonText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #d70000;
}

.secondaryButtonDisabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 45px;
  border: 1px solid #c2c2c2;
  border-radius: 100px;
  cursor: not-allowed;
}

.secondaryButtonDisabledText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #767676;
}
