.navContainer {
  margin-left: 1rem;
  padding-right: 2rem;
}
.inputContainer {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: 16px;
}
.textContainer {
  display: flex;
  align-items: center;
}
.showKey {
  color: #d70000;
}
.showKey:hover {
  cursor: pointer;
}

.locationInputsHolder {
  display: flex;
  gap: 1rem;
}
