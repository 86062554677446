.header {
  margin-left: 16px;
  margin-bottom: 24px;
  margin-top: 16px;
  font-weight: 700;
  font-size: 21px;
  line-height: 28.35px;
}
.filterContainer {
  position: sticky;
  top: 0;
  left: 0;
}
