.formContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
  max-height: 650px;
  min-height: 450px;
}
.responsiveFormContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background: white;
  padding: 2rem;
  gap: 12px;
}
.tireQuantityContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 320px;
}
.errorContainer {
  margin: 1rem 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.errorMessage {
  color: red;
  font-size: 11px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay .progressContainer {
  text-align: center;
}

.disposalContainer {
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 21px;
}

@media (max-width: 1023px) {
  .responsiveFormContainer {
    flex-direction: column;
    align-items: center;
  }
}
