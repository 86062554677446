.scheduleModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  isolation: isolate;
  position: absolute;
  max-width: 90%;
  max-height: 90%;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  gap: 24px;
}
.flexGroupButtons {
  display: flex;
  gap: 8px;
}
.scheduleModalPageTitle {
  font-size: 28px;
  /* margin-bottom: 1rem; */
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 256px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay .progressContainer {
  text-align: center;
}

.pagesLinksContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
.pagesLinks {
  display: flex;
  gap: 8px;
}

.pagesLinks button:not(:last-child) {
  border-right: thin solid #c2c2c2;
  padding-right: 8px;
}

.navLink {
  cursor: pointer;
  all: unset;
  color: #d70000;
  white-space: nowrap;
}
.navLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.active {
  color: black;
  font-weight: 700;
}

.navigationMessage {
  font-size: 10px;
}
