.orderSummaryContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 320px;
}

.orderSummaryLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 135%;
  display: flex;
  align-items: center;
  color: #141414;
}

.breakdownsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 320px;
}

.serviceAndTireSummariesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 320px;
  height: 302px;
}

.serviceSummaryContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 320px;
  height: 74px;
  background: #eeeeee;
  border-radius: 8px;
}

.serviceSummaryLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.tireInstallationLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.tireSummaryContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 320px;
  background: #eeeeee;
  border-radius: 8px;
}

.tireSummaryLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.servicesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 288px;
}

.line {
  width: 288px;
  height: 0px;
  border: 1px solid #c2c2c2;
}

.serviceContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 288px;
  height: 37px;
}

.tireSizeLabel {
  width: 150px;
  height: 21px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.servicePriceLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.promoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 320px;
}

.estimatedTotalContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 320px;
}

.estimatedTotalLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17.5px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.estimatedTotalPrice {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 17.5px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}
