.textfieldStyles {
  width: 320px;
}

.zipStateContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 320px;
}

.contactFormContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  margin-bottom: 2rem;
}
.contactTitle {
  font-size: 21px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.editText {
  all: unset;
  color: #d70000;
  padding-left: 7rem;
  font-size: small;
  font-weight: 400;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.editText:hover {
  text-decoration: underline;
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  isolation: isolate;
  position: fixed;
  padding: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  gap: 16px;
}
.modalPageTitle {
  font-size: 28px;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 8px;
  margin-top: 24px;
}

@media (max-width: 600px) {
  .contactFormContainer {
    width: 100%;
  }
  .textfieldStyles {
    width: 100%;
  }
}
