.containerWrapper {
	
}
.container {
	/* border: thin solid #c2c2c2;
	border-radius: 8px; */
	padding: 1rem;
}

.reportsHolder {
	width: 100%;
	margin-top: 2rem;
}

.report {
	width: 100;
	max-width: 50rem;
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: end;
	border-bottom: thin solid #c2c2c2;
	padding-bottom: 1rem;
}

.reportButton {
	width: 100%;
	background-color: #d70000;
	color: white;
	padding: .5rem 1.5rem;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	cursor: pointer;
}

.reportButton:hover {
	background-color: #b50000;
}

.downloadArea {
	justify-self: right;
}

.downloadLink {
	text-decoration: none;
	color: #d70000;
	font-weight: bold;
	font-size: 16px;
	padding: .5rem 1rem;
	border-radius: 1rem;
}

.downloadLink:hover {
	text-decoration: underline;
}