.tableTopperMain {
    width: 100%;
    display: flex;
    padding: 1rem;
    border: thin solid #C2C2C2;
    border-radius: .5rem;
    position: relative;
    cursor: pointer;
}

.tableTopperMain.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

.tableTopperContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
}

.toggleHolder {
    position: absolute;
    right: 1rem;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .1s;
}

.toggleHolder.open {
    transform: translateY(-50%) rotate(180deg);
}

.toggleHolder>img {
    width: 1rem;
}