.slot {
  min-height: 4rem;
  border-top: thin solid #ccc;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  user-select: none;
}

.slot:first-child {
  border-top: none;
}

/* .slot.empty {
  background-color: #f5f5f5;
} */

.slot.unavailable {
  background-color: #333;
  color: white;
}

.slotTime {
  font-weight: bold;
  width: max-content;
}

.slotBlockedReason {
  font-size: 12px;
  padding-left: 1rem;
  font-weight: bold;
}

.slotOrdersContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.slotOrder {
  border: thin solid #333;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-left: 1rem;
}

.slotOrder:active {
  outline: 5px auto #3b99fc;
}
.nameAndTireLocationContainer {
  display: flex;
  gap: 2rem;
}

.slotOrderName {
  font-weight: bold;
  font-size: 16px;
}
.tireLocation {
  font-size: 16px;
}

.slotOrderDetails {
  font-size: 14px;
  display: flex;
  gap: 1rem;
}

.slotOrderDetails > div {
  border-right: thin solid #ccc;
  padding-right: 1rem;
}

.slotOrderDetails > div:last-child {
  border-right: none;
  padding-right: 0;
}
