.selectQuantityContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 24px;
	width: 320px;
}

.frontRearSelectContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
}

.quantSelection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;
}

.quantSelectionTitle {
	height: 21px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 150%;
	display: flex;
	align-items: center;
}

.quantSelectionDropdown {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0px;
	isolation: isolate;
	width: 156px;
}

.tireDisposalContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 24px;
	width: 320px;
}

.tireDisposalDropdown {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0px;
	isolation: isolate;
	width: 320px;
}

.selectTireQuantityTitle {
	font-size: 21px;
}

@media (max-width: 350px) {
	.selectQuantityContainer {
		width: 100%;
	}
	.frontRearSelectContainer {
		flex-direction: column;
	}
}
