.chosenAddressIFrame {
  width: 500;
  height: 300;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 8px;
  margin-top: 24px;
}
.editAddressContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
