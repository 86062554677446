.inputsContainer {
  display: flex;
  flex-direction: column;
}
.checkBoxContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 8px;
  margin-top: 16px;
}
.topInputsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
}

.topInputsContainer > div {
  width: 100%;
}

.braintreeText {
  margin-bottom: 24px;
  text-align: center;
}
.headerTwo {
  font-weight: 700;
  font-size: 17.5px;
  margin-bottom: 16px;
}

/* styles below are specific to line item / cases modal */

.topInputsContainerLineItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
}
.topInputsContainerLineItem > div {
  margin-bottom: 16px;
}
.notesTitle {
  font-weight: 700;
  font-size: 17.5px;
}
.notesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 90%;
  border: thin solid #c2c2c2;
  border-radius: 5px;
  padding: 10px;
}
.notesBox {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.note {
  margin-bottom: 8px;
}
.addNoteButton {
  cursor: pointer;
}
.noTechs {
  color: #d70000;
}
.centerText {
  text-align: center;
}
.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.installImagesMainContainer {
  display: flex;
  flex-direction: column;
  max-height: 800px;
}
.imageLinks {
  all: unset;
  color: #d70000;
  cursor: pointer;
}
.imagesFlexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  overflow-y: auto;
  max-height: 600px;
}
.imageTitle {
  font-weight: 600;
  font-size: 16px;
}
.installImage {
  width: 300px;
  height: 533px;
  object-fit: cover;
}
