.container {
	display: flex;
	flex-direction: column;
}
.heading {
	font-size: 17px;
	font-weight: 700;
	margin-bottom: 16px;
}
.additionalActions {
	all: unset;
	color: #d70000;
	cursor: pointer;
	margin-bottom: 16px;
}

.additionalActions:disabled {
	color: rgba(0, 0, 0, 0.6);
	cursor: not-allowed;
}