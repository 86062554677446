.makeAppContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.nextAvailableText {
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
  color: #767676;
}
.availableText {
  color: black;
}

.addressComponentsContainer {
  position: relative;
}

.addressContainer {
  padding-top: 10px;
}

.addressResultsHolder {
  position: absolute;
  background-color: white;
  z-index: 1001;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem 0.25rem rgba(0, 0, 0, 0.2);
}

.addressResult {
  font-size: 14px;
  font-weight: lighter;
  padding: 1rem;
  width: 100%;
  display: flex;
  gap: 1rem;
}

.addressResultIcon::before {
  content: '📍';
}
.addressResultNoResult {
  font-style: italic;
}

.addressResult:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}

.addressInput.disabled {
  background-color: #f2f2f2;
}
