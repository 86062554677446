.zipCodesManagerMain {
  border: thin solid #c2c2c2;
  border-radius: .5rem;
  width: 100%;
}


.zipCodesManagerTitle {
  font-size: 22px;
}

.zipCodesManagerHeader {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  padding: 1rem;
}

.zipCodesManagerSubtitle {
  font-size: 12px;
  font-style: italic;
  padding-bottom: .25rem;
}

.zipCodesManagerChipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  width: 100%;
  max-height: 15rem;
  overflow-y: scroll;
  padding: 1rem;
  background-color: #f5f5f5;
  border-top: thin solid #c2c2c2;
  border-radius: .5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}