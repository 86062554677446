.orderDetailsContainer {
  margin-bottom: 2rem;
}

.orderDetailsForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 1rem;
  padding-top: 0.5rem;
}
.dropDownContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.orderDetailsText {
  font-size: 16px;
  font-weight: bold;
}
