.tableContainer {
  width: 100%;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  max-height: 82vh;
  overflow-y: auto;
  overflow-x: auto;
}
.progressContainer {
  min-height: 200px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}
.headerContainer {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  padding-top: 1rem;
}

.routesContainer {
  margin-top: 1rem;
  border-top: thin solid #ccc;
}
